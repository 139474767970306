// Generated by Framer (c75d380)

import { addFonts, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mWHsbXlZk"];

const serializationHash = "framer-8aJPN"

const variantClassNames = {mWHsbXlZk: "framer-v-xncl3w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "mWHsbXlZk", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-xncl3w", className, classNames)} data-border data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"mWHsbXlZk"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-05b8d98e-abe0-4137-a801-0f724209b039, rgb(224, 224, 224)) /* {\"name\":\"Border 3\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-5a88a987-9c48-4601-a7aa-ebe8a814d639, rgb(196, 238, 77))", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter-SemiBold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "600", "--framer-letter-spacing": "-0.02em", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-dc795c58-3589-4fb9-a9ab-4f342a5f364b, rgb(0, 0, 0)))"}}>Start chat</motion.p></React.Fragment>} className={"framer-cloe6g"} data-framer-name={"Label"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"dVqHklI9i"} style={{"--extracted-r6o4lv": "var(--token-dc795c58-3589-4fb9-a9ab-4f342a5f364b, rgb(0, 0, 0))"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-8aJPN[data-border=\"true\"]::after, .framer-8aJPN [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8aJPN.framer-nsgi73, .framer-8aJPN .framer-nsgi73 { display: block; }", ".framer-8aJPN.framer-xncl3w { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 8px 24px 8px 24px; position: relative; width: 111px; }", ".framer-8aJPN .framer-cloe6g { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8aJPN.framer-xncl3w { gap: 0px; } .framer-8aJPN.framer-xncl3w > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-8aJPN.framer-xncl3w > :first-child { margin-left: 0px; } .framer-8aJPN.framer-xncl3w > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 111
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerlqb82Qqy2: React.ComponentType<Props> = withCSS(Component, css, "framer-8aJPN") as typeof Component;
export default Framerlqb82Qqy2;

Framerlqb82Qqy2.displayName = "Elements/ChatButton";

Framerlqb82Qqy2.defaultProps = {height: 40, width: 111};

addFonts(Framerlqb82Qqy2, [])